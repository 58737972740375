// Auth Config
const tenantEnvironmentIds = {
  'dev-platinum': '341291c9-9810-4205-b4fc-c892bf233746',
  'staging-platinum': 'd0640c98-0c94-4a46-8ef6-dc97c7034d11',
  platinum: 'eb149e66-827d-49a0-8afc-c0287d6070a1',
  localhost: '341291c9-9810-4205-b4fc-c892bf233746',
};

const tenantAuthority = {
  'dev-platinum': 'https://platinumxca.b2clogin.com/platinumxca.onmicrosoft.com/B2C_1A_SIGNIN_DEV',
  'staging-platinum': 'https://platinumxca.b2clogin.com/platinumxca.onmicrosoft.com/B2C_1A_SIGNIN_STAGING',
  platinum: 'https://platinumxca.b2clogin.com/platinumxca.onmicrosoft.com/B2C_1A_SIGNIN_PROD',
  localhost: 'https://platinumxca.b2clogin.com/platinumxca.onmicrosoft.com/B2C_1A_SIGNIN_DEV',
};

const tenantScopes = {
  'dev-platinum': ['openid', 'offline_access'],
  'staging-platinum': ['openid', 'offline_access'],
  platinum: ['openid', 'offline_access'],
  localhost: ['openid', 'offline_access'],
};

const tenantScopesToken = {
  'dev-platinum': [
    'https://platinumxca.onmicrosoft.com/api-dev/read',
    'https://platinumxca.onmicrosoft.com/api-dev/write',
  ],
  'staging-platinum': [
    'https://platinumxca.onmicrosoft.com/api-staging/read',
    'https://platinumxca.onmicrosoft.com/api-staging/write',
  ],
  platinum: [
    'https://platinumxca.onmicrosoft.com/api-prod/read',
    'https://platinumxca.onmicrosoft.com/api-prod/write',
  ],
  localhost: [
    'https://platinumxca.onmicrosoft.com/api-dev/read',
    'https://platinumxca.onmicrosoft.com/api-dev/write',
  ],
};

const tenantGraphMeEndpoint = {
  'dev-platinum': 'https://dev-platinum.takecareapi.com/greybox-api/account/?self=true',
  'staging-platinum': 'https://staging-platinum.takecareapi.com/greybox-api/account/?self=true',
  platinum: 'https://platinum.greybox.ca/greybox-api/account/?self=true',
  localhost: 'https://dev-platinum.takecareapi.com/greybox-api/account/?self=true',
};

// App Insights Config
const tenantKeyConfig = {
  localhost: '2b4f1bf3-ea9a-4cf8-9234-6ae7b700fa4d',
  'dev-platinum.greybox.ca': '2b4f1bf3-ea9a-4cf8-9234-6ae7b700fa4d',
  'staging-platinum.greybox.ca': 'ffef767f-3ce8-4ecd-b558-efec2056ef50',
  'platinum.greybox.ca': 'e6beaf36-6dea-45f3-920f-b6cde579610b',
};

const tenantHeaderConfig = {
  'dev-platinum.greybox.ca': 'dev-ca.takecareapi.com',
  'staging-platinum.greybox.ca': 'staging-ca.takecareapi.com',
  'platinum.greybox.ca': 'ca.takecareapi.com',
};

// API Config
const tenantDomainConfigs = {
  localhost: 'dev-platinum.takecareapi.com',
  'dev-platinum.greybox.ca': 'dev-platinum.takecareapi.com',
  'platinum.greybox.ca': 'platinum.takecareapi.com',
  'staging-platinum.greybox.ca': 'staging-platinum.takecareapi.com',
};

export {
  tenantEnvironmentIds,
  tenantAuthority,
  tenantScopes,
  tenantScopesToken,
  tenantGraphMeEndpoint,
  tenantKeyConfig,
  tenantHeaderConfig,
  tenantDomainConfigs,
};
