import React, { useEffect, useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Skeleton,
  Alert,
  Autocomplete,
  TextField,
  Box,
  MenuItem,
  Avatar,
  Badge,
  IconButton,
  Typography,
  Divider,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import Row from '../components/Row';
import FormTextField from '../components/form-inputs/FormTextField';
import Language from '../components/form-inputs/Language';
import PhoneNumber from '../components/form-inputs/PhoneNumber';
import FormSelect from '../components/form-inputs/FormSelect';
import { greyboxApiActions } from '../redux/api';
import ProfilePictureUploadDialog from '../components/patient-profile/layout/ProfilePictureUploadDialog';

const UserInformation = () => {
  const { t, i18n } = useTranslation();
  const { user, media } = greyboxApiActions;

  const { data = {}, isLoading, isError, refetch } = user.get();
  const [updateUser] = user.update();
  const [sendMedia] = media.add();
  const [profilePictureOpen, setProfilePictureOpen] = useState(false);

  const allTimezones = moment.tz.names();

  const weightUnitChoice = [
    { value: 'kg', label: t('Kilogram') },
    { value: 'lb', label: t('Pound') },
  ];

  const temperatureUnitChoice = [
    { value: '°C', label: t('Celsius') },
    { value: '°F', label: t('Fahrenheit') },
  ];

  const formik = useFormik({
    initialValues: {
      title: data?.title || '',
      first_name: data?.first_name || '',
      middle_name: data?.middle_name || '',
      last_name: data?.last_name || '',
      email: data?.email || '',
      mobile: data?.mobile || '',
      language: data?.language || 'en',
      timezone: data?.timezone || moment.tz.guess(),
      temperature_unit: data?.temperature_unit || '°C',
      weight_unit: data.weight_unit || 'kg',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      title: yup.string(),
      first_name: yup.string().required(t('required')),
      middle_name: yup.string(),
      last_name: yup.string().required(t('required')),
      email: yup.string().email(),
      mobile: yup.string(),
      language: yup.string().required(t('required')),
      timezone: yup.string().required(t('required')),
      temperature_unit: yup.string().required(t('required')),
      weight_unit: yup.string().required(t('required')),
    }),
    onSubmit: (values) => {
      updateUser({ body: values, feedback: { success: t('User updated') } })
        .unwrap()
        .then(() => {
          formik.setSubmitting(false);
          refetch();
        })
        .catch((error) => {
          formik.setSubmitting(false);
          if (error.status === 400 && error.data) {
            const errors = error.data;
            formik.setErrors(errors);
          }
        });
    },
  });

  useEffect(() => {
    if (formik.values.language && formik.values.language !== i18n.resolvedLanguage) {
      i18n.changeLanguage(formik.values.language);
      moment.locale(formik.values.language);
    }
  }, [formik.values.language, i18n]);

  if (isLoading) {
    return (
      <Layout width="md">
        <Card sx={{ m: 1 }}>
          <CardHeader title={<Skeleton width="40%" role="progressbar" />} />
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <Skeleton variant="circular" width={100} height={100} role="progressbar" />
            </Box>
            {/* New skeleton row for title/middle name */}
            <Row>
              <Skeleton variant="rectangular" width="48%" height={56} sx={{ mx: 1 }} role="progressbar" />
              <Skeleton variant="rectangular" width="48%" height={56} sx={{ mx: 1 }} role="progressbar" />
            </Row>
            <Row>
              <Skeleton variant="rectangular" width="48%" height={56} sx={{ mx: 1 }} role="progressbar" />
              <Skeleton variant="rectangular" width="48%" height={56} sx={{ mx: 1 }} role="progressbar" />
            </Row>
            <Row>
              <Skeleton variant="rectangular" width="48%" height={56} sx={{ mx: 1 }} role="progressbar" />
              <Skeleton variant="rectangular" width="48%" height={56} sx={{ mx: 1 }} />
            </Row>
            <Row>
              <Skeleton variant="rectangular" width="48%" height={56} sx={{ mx: 1 }} role="progressbar" />
              <Skeleton variant="rectangular" width="48%" height={56} sx={{ mx: 1 }} role="progressbar" />
            </Row>
            <Row>
              <Skeleton variant="rectangular" width="48%" height={56} sx={{ mx: 1 }} role="progressbar" />
              <Skeleton variant="rectangular" width="48%" height={56} sx={{ mx: 1 }} role="progressbar" />
            </Row>
          </CardContent>
          <CardActions>
            <Skeleton
              variant="rectangular"
              width={100}
              height={36}
              sx={{ ml: 'auto' }}
              role="progressbar"
            />
          </CardActions>
        </Card>
      </Layout>
    );
  }

  if (isError) {
    return (
      <Layout width="md">
        <Alert severity="error" onClose={() => refetch()}>
          {t('Failed to load user information. Please try again.')}
        </Alert>
      </Layout>
    );
  }

  return (
    <Layout width="md">
      <Card sx={{ m: 1 }}>
        <CardHeader title={t('Your Information')} />
        <form onSubmit={formik.handleSubmit}>
          <CardContent>
            {/* Profile Picture Section */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => setProfilePictureOpen(true)}
                    sx={{ backgroundColor: 'white', p: 0.5 }}
                  >
                    <PhotoCamera />
                  </IconButton>
                }
              >
                <Avatar
                  alt={`${formik.values.first_name} ${formik.values.last_name}`}
                  src={data.profile_picture_url}
                  sx={{ width: 100, height: 100 }}
                />
              </Badge>
            </Box>

            {/* Name Section */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                {t('Name')}
              </Typography>
              <Row>
                <FormTextField
                  formik={formik}
                  name="first_name"
                  label={t('First name')}
                  required
                  disabled={formik.isSubmitting}
                />
                <FormTextField
                  formik={formik}
                  name="last_name"
                  label={t('Last name')}
                  required
                  disabled={formik.isSubmitting}
                />
              </Row>
              <Row>
                <FormTextField
                  formik={formik}
                  name="title"
                  label={t('Title')}
                  disabled={formik.isSubmitting}
                  placeholder={t('e.g., Dr., Prof.')}
                />
                <FormTextField
                  formik={formik}
                  name="middle_name"
                  label={t('Middle Name')}
                  disabled={formik.isSubmitting}
                />
              </Row>
            </Box>

            <Divider sx={{ mb: 4 }} />

            {/* Contact Information Section */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                {t('Contact Information')}
              </Typography>
              <Row>
                <FormTextField
                  formik={formik}
                  name="email"
                  label={t('Email')}
                  type="email"
                  disabled={formik.isSubmitting}
                />
                <PhoneNumber
                  formik={formik}
                  name="mobile"
                  label={t('Mobile')}
                  disabled={formik.isSubmitting}
                />
              </Row>
            </Box>

            <Divider sx={{ mb: 4 }} />

            {/* Preferences Section */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 500 }}>
                {t('Preferences')}
              </Typography>
              <Row>
                <Language formik={formik} name="language" disabled={formik.isSubmitting} />
                <Autocomplete
                  options={allTimezones}
                  value={formik.values.timezone}
                  onChange={(event, newValue) => formik.setFieldValue('timezone', newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Timezone')}
                      required
                      variant="outlined"
                      sx={{ mx: 1 }}
                      disabled={formik.isSubmitting}
                    />
                  )}
                  sx={{ pr: 2 }}
                />
              </Row>
              <Row>
                <FormSelect
                  formik={formik}
                  name="temperature_unit"
                  label={t('Temperature Unit')}
                  sx={{ mx: 1 }}
                  disabled={formik.isSubmitting}
                >
                  {temperatureUnitChoice.map((unit) => (
                    <MenuItem key={unit.value} value={unit.value}>
                      {unit.label}
                    </MenuItem>
                  ))}
                </FormSelect>
                <FormSelect
                  formik={formik}
                  name="weight_unit"
                  label={t('Weight Unit')}
                  sx={{ mx: 1 }}
                  disabled={formik.isSubmitting}
                >
                  {weightUnitChoice.map((unit) => (
                    <MenuItem key={unit.value} value={unit.value}>
                      {unit.label}
                    </MenuItem>
                  ))}
                </FormSelect>
              </Row>
            </Box>
          </CardContent>

          <CardActions>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              sx={{ ml: 'auto' }}
              disabled={formik.isSubmitting || !formik.dirty}
              loading={formik.isSubmitting}
            >
              {t('Save')}
            </LoadingButton>
          </CardActions>
        </form>
      </Card>
      <ProfilePictureUploadDialog
        open={profilePictureOpen}
        handleClose={() => setProfilePictureOpen(false)}
        onSave={async (file) => {
          try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', 'image');
            const mediaData = await sendMedia({ body: formData }).unwrap();
            await updateUser({
              body: { profile_picture: mediaData.id },
              feedback: { success: t('Profile picture updated') },
            }).unwrap();
            setProfilePictureOpen(false);
          } catch (error) {
            console.error('Failed to upload profile picture:', error);
          }
        }}
      />
    </Layout>
  );
};

export default UserInformation;