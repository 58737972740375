import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  FormLabel,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../../redux/api';
import { getExtensionValueFromType } from '../../../utils';
import {
  Clinic,
  EthnicGroup,
  FormTextField,
  Hin,
  Labels,
  Language, MultiSelect, Pharmacy, PhoneNumber, Sex
} from '../../form-inputs';
import BirthDate, { formatBirthDateForPost } from '../../form-inputs/BirthDate';
import { ZipCode, zipCodeSchema } from '../../form-inputs/ZipCode';
import ArchiveModal from '../../modals/ArchiveModal';
import Row from '../../Row';
import Telecom from '../Telecom';
import { Identifiers } from '../../form-inputs/Identifiers';

const BasicInfoTab = () => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const configs = clinic.config.features_enable;
  const { uuid } = useParams();
  const {
    account, diagnosis,
  } = greyboxApiActions;

  const { data = [] } = account.get(uuid);
  const [updateAccount] = account.update();
  const [open, setOpen] = useState(false);
  const { access } = useSelector((state) => state.user);
  const diagnosisSelector = diagnosis.list({ clinic: clinic.id });

  const informations = useFormik({
    initialValues: {
      user: data.user,
      labels: data.labels || [],
      birthDate: data.birthDate ? moment(data.birthDate) : null,
      telecom: data.telecom || [],
      zipCode: data.zipCode || '',
      hin_number: data.hin?.hin_number,
      hin_exp: data.hin?.hin_exp,
      clinic: data.company?.[0] || clinic.id,
      gender: data.gender || 'U',
      ethnic_group: data.ethnic_group || 'U',
      primaryDiagnosis: data?.diagnosisDetails
        ?.filter((d) => data.diagnosis.includes(d.diagnosis_key))
        .map((d) => ({ id: d.uuid, label: d.diagnosis_name, key: d.diagnosis_key })) || [],
      secondaryDiagnosis: diagnosisSelector.data
        ?.filter((d) => data.secondary_diagnosis.includes(d.uuid))
        .map((d) => ({ id: d.uuid, label: d.diagnosis_name, key: d.diagnosis_key })) || [],

      staffs: data.staffs ? data.staffs.map((staff) => ({ id: staff })) : [],
      pharmacy: getExtensionValueFromType(data.extension, 'patient-preferred-pharmacy'),
      identifier: data.identifier || [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      user: Yup.object().shape({
        title: Yup.string(),
        first_name: Yup.string().required(t('required')),
        middle_name: Yup.string(),
        last_name: Yup.string().required(t('required')),
        email: Yup.string().email(),
        mobile: Yup.string(),
      }),
      birthDate: Yup.date().nullable(),
      zipCode: zipCodeSchema,
      clinic: Yup.string(),
      pharmacy: Yup.string(),
      primaryDiagnosis: Yup.array().of(Yup.object()),
      secondaryDiagnosis: Yup.array().of(Yup.object()),
      labels: Yup.array().of(Yup.object()),
      identifier: Identifiers.validationSchema,
      ...Hin.validationSchema.fields,
    }),
    onSubmit: (values) => {
      // Identify dirty fields
      const dirtyFields = Object.keys(values).filter(
        (key) => !isEqual(values[key], informations.initialValues[key])
      );


      // Build the body object with dirty fields
      const body = {};
      dirtyFields.forEach((key) => {
        body[key] = values[key];
      });

      // Apply transformations to the body object
      if (body.staffs) {
        body.staffs = body.staffs.map((staff) => staff.id);
      }

      if (body.primaryDiagnosis) {
        body.diagnosis = body.primaryDiagnosis.map((d) => d.key);
        delete body.primaryDiagnosis;
      }

      if (body.secondaryDiagnosis) {
        body.secondary_diagnosis = body.secondaryDiagnosis.map((d) => d.id);
        delete body.secondaryDiagnosis;
      }

      if (body.clinic) {
        body.company = [body.clinic];
        delete body.clinic;
      }

      if (body.labels) {
        body.labels = body.labels.map((label) => label.id);
      }

      if ('hin_number' in body || 'hin_exp' in body) {
        const hinNumber = 'hin_number' in body ? body.hin_number : informations.initialValues.hin_number;
        const hinExp = 'hin_exp' in body ? body.hin_exp : informations.initialValues.hin_exp;

        if (hinNumber) {
          body.hin = {
            hin_number: hinNumber,
            hin_exp: hinExp ? hinExp : null,
          };
        } else if (hinNumber === '') {
          body.hin = null;
        }
        // Remove 'hin_number' and 'hin_exp' from 'body' as they are now included in 'hin'
        delete body.hin_number;
        delete body.hin_exp;
      }

      if (body.birthDate) {
        body.birthDate = formatBirthDateForPost(body.birthDate);
      }

      if (body.pharmacy) {
        body.extension = [
          {
            url: 'https://ca.takecareapi.com/fhir/StructureDefinition/patient-preferred-pharmacy',
            valueId: body.pharmacy,
          },
        ];
        delete body.pharmacy;
      }

      // Update account with the body containing only dirty fields
      updateAccount({ id: uuid, body })
        .unwrap()
        .then(() => {
          informations.setSubmitting(false);
        })
        .catch((error) => {
          informations.setSubmitting(false);
          if (error.status === 400 && error.data) {
            const errors = error.data;
            informations.setErrors(errors);
          }
        });
    },
  });

  return (
    <>
      <form onSubmit={informations.handleSubmit}>
        <Typography variant="h5">
          {t('Patient Information')}
        </Typography>

        <Row>
          <FormTextField formik={informations} name="user.first_name" required label={t('First Name')} />
          <FormTextField formik={informations} name="user.last_name" required label={t('Last Name')} />
        </Row>
        <Row>
          <FormTextField formik={informations} name="user.title" label={t('Title')} />
          <FormTextField formik={informations} name="user.middle_name" label={t('Middle Name')} />
        </Row>
        <Row>
          <FormTextField formik={informations} name="user.email" label={t('Email')} />
          <PhoneNumber formik={informations} name="user.mobile" />
        </Row>
        <Row>
          <ZipCode formik={informations} sx={{ mx: 1 }} />
          <BirthDate formik={informations} />
        </Row>
        <Row>
          <Language formik={informations} name="user.language"/>
          <Sex formik={informations} />
        </Row>
        <Row>
          <EthnicGroup formik={informations} />
          <Hin formik={informations} />
        </Row>

        {access === 'PT' && (
          <>
            <Row>
              <Clinic formik={informations} />
              <Labels formik={informations} />
            </Row>
            <Identifiers informations={informations} clinic={clinic} role={data.acc_type} />
            <MultiSelect
              formik={informations}
              type="diagnosis"
              level="primary"
              label={t('Primary Diagnosis')}
              name="primaryDiagnosis"
            />
            {configs.secondary_diagnosis && (
              <MultiSelect
                formik={informations}
                type="diagnosis"
                level="secondary"
                name="secondaryDiagnosis"
                label={t('Secondary Diagnosis')}
              />
            )}
            <MultiSelect formik={informations} type="staffs" label={t('Assigned HCP')} name="staffs" />
          </>
        )}
        <Telecom informations={informations} />
        <Row>
          {access === 'PT' && (
            <Box sx={{ mx: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormLabel>{t('Additional Information')}</FormLabel>
              </Box>
              <Box
                sx={{
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  display: 'flex',
                  flexDirection: 'row',
                  mt: 1,
                  borderRadius: 3,
                  p: 1,
                }}
              >
                <Box sx={{ pl: 1, width: '100%' }} display="flex" flexDirection="column">
                  <Typography>
                    <strong>{data.name || ''}</strong>
                  </Typography>
                  <Typography>
                    {`${t('Registered since')}: ${moment(data.createdDate).format('LLL')}`}
                  </Typography>
                  <Typography>
                    {`${t('Last active on')}: ${moment(data.lastActive).format('LLL')}`}
                  </Typography>

                </Box>
              </Box>
            </Box>
          )}
          <Pharmacy formik={informations} />
        </Row>
        <Box sx={{ justifyContent: 'flex-end', display: 'flex', gap: 1 }}>
          {access === 'PT' && (
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpen(true)}
              disabled={informations.isSubmitting}
            >
              {t('Archive')}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => informations.resetForm()}
            disabled={!informations.dirty || informations.isSubmitting}
          >
            {t('Reset')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={informations.isSubmitting}
          >
            {t('Save')}
          </LoadingButton>
        </Box>
      </form>
      <ArchiveModal
        open={open}
        firstName={informations.values.firstName}
        handleClose={() => setOpen(false)}
        uuid={uuid}
      />
    </>
  );
};

export default BasicInfoTab;
